import { MessageDescriptor } from 'react-intl';
import { PaymentsType } from './types';
import claimsPortalMessages from './messages/commonMsg';

export const CP_ROOT = (process.env.VITE_CP_ROOT || process.env.NEXT_PUBLIC_CP_ROOT) as 'xcover' | 'rentalcover';

export const STATUS_ACTION_REQUIRED = 'ACTION REQUIRED';

export const CLAIM_CENTRE_STATUS = 'claim-centre-status';

export const buttonLabelMessage: Record<Exclude<PaymentsType, 'refund'>, MessageDescriptor> = {
  payout: claimsPortalMessages.getMyPayment,
  charge: claimsPortalMessages.payExcess,
};

export const DEFAULT_MAX_SIZE = 10240;

export const REVIEW_SECTION_NAME = 'review';
